exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-templates-all-episodes-tsx": () => import("./../../../src/templates/allEpisodes.tsx" /* webpackChunkName: "component---src-templates-all-episodes-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-catalog-tsx": () => import("./../../../src/templates/catalog.tsx" /* webpackChunkName: "component---src-templates-catalog-tsx" */),
  "component---src-templates-new-template-tsx": () => import("./../../../src/templates/newTemplate.tsx" /* webpackChunkName: "component---src-templates-new-template-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-podcast-episode-tsx": () => import("./../../../src/templates/podcastEpisode.tsx" /* webpackChunkName: "component---src-templates-podcast-episode-tsx" */),
  "component---src-templates-podcast-tsx": () => import("./../../../src/templates/podcast.tsx" /* webpackChunkName: "component---src-templates-podcast-tsx" */)
}

