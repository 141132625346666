module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/logo/logo-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9701ae960e4de994101eeb1b00e963f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoIndex":false,"openGraph":{"type":"website","locale":"en_US","site_name":"Collato"},"twitter":{"handle":"@CollatoHQ","site":"@CollatoHQ","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://collato.com","noQueryString":true,"noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-TDYLB9147S","cookieName":"tracking","anonymize":true,"cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"XXXXXXX","anonymize":true,"cookieName":"do-not-enable","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"XXXXXXX","cookieName":"do-not-enable"},"hotjar":{"hjid":"2144790","hjsv":"6","cookieName":"tracking"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
